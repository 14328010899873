.about {
  background-color: rgb(36, 33, 33);
  margin-top: -60px;
  margin-bottom: -20px;
  height: 800px;
}
#aboutPic {
  position: relative;
  float: left;
  width: 30%;
  margin: 15px;
  z-index: 1;
}
